// api.js (novo arquivo)
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export async function fetchAllResources() {
  try {
    const paginasSupData = await fetchResource('/api/paginas-criadas', 'Erro ao buscar páginas criadas');
    const destinosData = await fetchResource('/api/pacotes', 'Erro ao buscar destinos');
    const cruzeirosData = await fetchResource('/api/cruzeiros/pacotes', 'Erro ao buscar cruzeiros');
    const cruzeirosCatData = await fetchResource('/api/cruzeiros/categorias', 'Erro ao buscar categorias de cruzeiros');
    const textosData = await fetchResource('/api/textos', 'Erro ao buscar textos');

    return { paginasSupData, destinosData, cruzeirosData, cruzeirosCatData, textosData };
  } catch (error) {
    throw new Error(error);
  }
}

async function fetchResource(endpoint, errorMessage) {
  try {
    const response = await api.get(endpoint);
    return response.data;
  } catch (error) {
    throw new Error(errorMessage);
  }
}
