import React from "react";
import "../../assets/css/folheto.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Folheto = ({ data }) => {
  return (
    <div className="d-inline-block w-auto mw-auto justify-content-between">
      <LazyLoadImage
        className="d-block mw-auto"
        src={`https://agaxtur-uploads.s3.amazonaws.com/${data.imageUrl}`}
        alt={data.title}
        width="345"
      />

      <a
        className="d-inline-block my-3 px-4 py-3 downloadButton"
        href={`https://agaxtur-uploads.s3.amazonaws.com/${data.pdfUrl}`}
      >
        DOWNLOAD DO FOLHETO
      </a>
    </div>
  );
};

export default Folheto;
