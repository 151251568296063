import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import slugify from 'slugify';
import './styles.css';

export const CasamentoCard = ({ pacote, slugDestino }) => {
    // const buttonVisible = showButton !== undefined ? showButton : false;
    const [loadingContext, setLoadingContext] = useState(true);
    const slug = slugify(pacote.title, { lower: true });

    return (
        <div key={pacote.title} className="col-6 col-md-3 col-lg-3 mb-2" id="geral-pacote-card-container"  style={{ cursor: 'pointer' }}>
            <a href={`/casamento/${slugDestino}/${slug}/${pacote._id}`}>
                <div className="pacote-box h-100">
                    <div className="pacote-img" id={loadingContext.toString() && "skeleton-image"}>
                        <LazyLoadImage
                            className="img-fluid"
                            alt={""}
                            onLoadCapture={() => setLoadingContext(false)}
                            src={`https://agaxtur-uploads.s3.amazonaws.com/${pacote.thumbnail ? pacote.thumbnail : pacote.imageUrl[0]}`}
                        />

                    </div>
                    <div className="pacote-info p-2">
                        {pacote.noites ?
                            <span className="badge bg-primary fs--1 me-2" style={{ display: 'flex', gap: 5, whiteSpace: 'nowrap' }}>
                                <i className="fas fa-calendar" ></i>
                                {pacote.noites} noites
                            </span> : null
                        }

                    </div>
                    <div className="pacote-detalhes p-3" >
                        <h2 className="fs-1 fs-md-2" >{pacote.title}</h2>
                        <div
                            className="pacote-descricao"
                            dangerouslySetInnerHTML={{ __html: `${pacote.description}` }}>
                        </div>
                        <div>
                            {pacote.price ?
                                <div>
                                    <small className="pacote-texto-valor">{pacote.textoValor}</small> <br />
                                    <h3>{pacote.price}</h3>
                                </div>
                                : null
                            }
                            {pacote.valorTotal ?
                                <div className="pactote-texto-total">
                                    {pacote.valorTotal}
                                </div>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </a>
        </div>
    );
};

