import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function StoreCard({ store }) {
    const emailURL = "mailto:";

    return (
        <div className="col-lg-4 col-sm-12 mb-3">
            <div className="card">
                {store.imageUrl ? (
                    <div className="loja-img">
                        <LazyLoadImage
                            className="card-img-top"
                            alt={store.title}
                            src={`https://agaxtur-uploads.s3.amazonaws.com/${store.imageUrl}`}
                        />
                    </div>
                ) : null}
                <div className="card-body">
                    <h5 className="card-title">{store.title}</h5>
                    <p className="card-text">{store.description}</p>
                    <ul className="list-unstyled">
                        <li>
                            <FontAwesomeIcon
                                icon={solid("location-dot")}
                                className="mr-2"
                            />
                            &nbsp; {store.endereco}
                        </li>
                        <li>
                            <FontAwesomeIcon
                                icon={solid("phone")}
                                className="mr-2"
                            />
                            &nbsp; {store.telefone}
                        </li>
                        <li>
                            <a
                                href={`${emailURL}${store.email}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon
                                    icon={solid("envelope")}
                                    className="mr-2"
                                />
                                &nbsp; {store.email}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
