import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useReducerActions from "../../reducer";
import StoreCard from "./StoreCard/index";

export default function Stores() {
  const dispatch = useDispatch();
  const { SET_STORES } = useReducerActions();
  const state = useSelector((state) => state);
  const { stores } = state.extras;

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/lojas`
        );
        dispatch(SET_STORES(data));
      } catch (err) {
        console.error(err);
      }
    };
    if (!stores.length > 0) {
      fetch();
    }
    // eslint-disable-next-line
  }, []);

  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [filteredStores, setFilteredStores] = useState([]);

  useEffect(() => {
    if (selectedState !== "") {
      setSelectedCity("");
      setFilteredStores(stores.filter(loja => loja.estado === selectedState));
    } else {
      setFilteredStores([]);
    }
  }, [selectedState, stores]);

  useEffect(() => {
    if (selectedCity !== "") {
      return setFilteredStores(stores.filter(loja => loja.cidade === selectedCity));
    }
    if (selectedCity === "") {
      return setFilteredStores(stores.filter(loja => loja.estado === selectedState));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCity, stores]);

  return (
    <div className="mt-5 py-5">
      <div className="container">
        <div className="row my-4 text-center">
          <h2>Nossas Lojas</h2>
        </div>
        <div className="row">
          <div className="col-lg-4 offset-lg-2 col-sm-12 mb-3">
            <div className="card ">
              <div className="card-body">
                <h5 className="card-title">Filtrar por Estado</h5>
                <select
                  className="form-select"
                  value={selectedState}
                  onChange={(e) => setSelectedState(e.target.value)}
                >
                  <option value="">Todos</option>
                  {[...new Set(stores.map(loja => loja.estado))].map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 mb-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Filtrar por Cidade</h5>
                <select
                  className="form-select"
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value)}
                >
                  <option value="">
                    {selectedState.length > 0 ? "Selecione a cidade" : "Selecione o estado"}</option>
                  {[...new Set(stores.filter(loja => loja.estado === selectedState).map(loja => loja.cidade))].map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        {
          filteredStores.length > 0 ? (
            <div className="row">
              {filteredStores
                .slice()
                .sort((a, b) => {
                  const nameA = a.title || ""; 
                  const nameB = b.title || ""; 
                  return nameA.localeCompare(nameB);
                })
                .map((store) => (
                  <StoreCard key={store._id} store={store} />
                ))}
            </div>
          ) : (
            <div className="row">
              {stores
                .slice()
                .sort((a, b) => {
                  const nameA = a.title || ""; 
                  const nameB = b.title || "";
                  return nameA.localeCompare(nameB);
                })
                .map((store) => (
                  <StoreCard key={store._id} store={store} />
                ))}
            </div>
          )}
      </div>
    </div>

    
  );
}