import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormularioSharp from "../components/FormularioSharp";
import { PacoteCard, SkeletonCard } from "../components/PacoteCard";
import useReducerActions from "../reducer";

export default function Circuitos() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { SET_TRAVEL_M } = useReducerActions();
  const { travel_p, travel_m } = state.travel;
  const [circuitos, setCircuitos] = useState("");

  const fetch = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/destinos/categorias`
      );
      dispatch(SET_TRAVEL_M(data));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!travel_m.length > 0) {
      fetch();
    }

    if (travel_m.length > 0) {
      setCircuitos(travel_m.map(item => item).find((item) => item._id === '64caba447dcdccb68595e168'))
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [travel_m])

  return (
    <>
      <div id="circuitos-container" className="destinos">
        <header className="text-center" style={{ backgroundColor: '#0d4990' }}>
          <h1>{circuitos.title}</h1>
          <h3>
            <span className="badge bg-secondary">{circuitos.description}</span>
          </h3>
        </header>
        <div className="container">
          <div id="pacotes" className="py-3 mt-4">
            <h2 className="mb-4 text-center">
              <span className="azul-claro">Confira os circuitos Agaxtur!</span>
            </h2>
            {travel_p.length > 0 ?
              <div className="row mb-4">
                {travel_p.filter(
                  (pacote) =>
                    pacote.local === "circuitos"
                ).map((pacote) => {
                  return <PacoteCard uniqueLink={false} circuito={true} pacote={pacote} key={pacote._id} />;
                })}
              </div>
              :
              <div className="row mb-4">
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
              </div>
            }
          </div>
        </div>
      </div>
      <FormularioSharp />
    </>
  );
};
