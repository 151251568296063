import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";

const Paginas = () => {
  const location = useLocation();
  const state = useSelector(state => state);
  const { pages_b } = state.extras;
  const [title, setTitle] = useState([]);
  const [content, setContent] = useState([]);
  const [image, setImage] = useState([]);
  const [form, setForm] = useState([]);

  document.title = title + " - Agaxtur Viagens";

  useEffect(() => {
    //eslint-disable-next-line
    const filtered_page = pages_b
      .filter((pagina) => pagina.slug === location.pathname.replace('/', ''))[0]

    if (filtered_page) {
      setTitle(filtered_page.title);
      setContent(filtered_page.content);
      setImage(filtered_page.imageUrl);
      setForm(filtered_page.formulario);
    }
  }, [location.pathname, pages_b]);

  return (
    <main>
      {image ?
        <img src={`https://agaxtur-uploads.s3.amazonaws.com/${image}`} className="img-fluid mb-5" alt={title} />
        : null}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="text-center mb-4">{title}</h2>
            <div dangerouslySetInnerHTML={{ __html: content }} />
            {form ?
              <div className="bg-light p-4 mt-4 rounded">
                <div className="col-12 text-center">
                  <h3 className="text-center mb-4">Vamos Conversar</h3>
                  <p>Deixe seus dados e entraremos em contato em breve.</p>
                  <iframe
                    title="Formulário"
                    src={`${process.env.REACT_APP_API_URL}/api/formularios/${form}`}
                    width="100%"
                    height="680"
                  />
                </div>
              </div> : null}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Paginas;
