import React from "react";
import { useFetch } from "../../hooks/useFetch";

function FormularioSharp() {

  const { data } = useFetch(`${process.env.REACT_APP_API_URL}/api/textos/644a9a468c2977446e2d49df`);

  return (
    <>
      <div className="outros" style={{ backgroundImage: `url(https://agaxtur-uploads.s3.amazonaws.com/${data.imageUrl})` }}>
        <div className="container">
          <div className="row pt-5 pb-4">
            <div className="col-lg-12 text-center">
              <h2 className="mb-3 text-light">
                {data.title}
              </h2>
              <p>
                <b>{data.texto}</b> <br />
                <br />
                <a
                  href="https://lp.agaxtur.com.br/atendimento"
                  target="_blank"
                  rel="noreferrer"
                  title="Encontre outros destinos"
                  className="btn btn-large btn-secondary"
                >
                  Encontre outros destinos
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default FormularioSharp;
