import React from "react";
import { useSelector } from "react-redux";
import icn1 from "../../images/icons/icon-location.svg";
import { PacoteCard, SkeletonCard } from "../PacoteCard";


const Nacionais = ({ data }) => {
  const state = useSelector((state) => state);
  const { texts } = state.extras;

  return (
    <section className="py-3 overflow-hidden grade-imagens" id="populares">
      <div className="container bg-offcanvas-gray-right">
        <div className="row gx-2 mb-2">
          <div className="col-12 col-lg-3 mb-2">
            <div className="gradient-base-dark-blue gradient-row h-100 d-flex align-items-center">
              <div className="row g-0">
                <div className="col-12">
                  <div className="p-lg-4 px-2 py-3 px-md-3 text-light">
                    <h2 className="my-2 text-light"> <img src={icn1} alt="section icon" style={{marginRight: 10}} width="25"/>
                      {texts && texts.filter((titulo) => titulo._id === "644a988ef3291aa9d5a21fb4")[0].title}
                    </h2>
                    <p>
                      {texts && texts.filter((titulo) => titulo._id === "644a988ef3291aa9d5a21fb4")[0].texto}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            data ?
              data.filter(
                (pacote) => pacote.featured === true && pacote.local === "brasil"
              ).map((pacote) => (
                <PacoteCard pacote={pacote} key={pacote._id} />
              ))
              :
              <>
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
              </>
          }
        </div>
      </div>
    </section >
  );
};

export default Nacionais;
