import React, { useMemo, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick/lib/slider";
import slugify from 'slugify';

const breakPoints = [
  {
    breakpoint: 1200,
    settings: {
      slidesToShow: 4,
    }
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3,
    }
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2
    }
  },
  {
    breakpoint: 380,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  },
];

const buttonVisible = false;

function Ofertas({ data }) {
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    waitForAnimate: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: breakPoints,
    appendDots: dots => (
      <div>
        <ul className="carousel-dot" style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
  };

  const pacotes = useMemo(
    () =>
      data.filter(
        (pacote) => pacote.featured === true && pacote.carouselOfertas === true
      )
        .sort((p1, p2) => p1.ordem > p2.ordem),
    [data]
  );
  const [show, setShow] = useState(false);
  return (
    <div id="ofertas">
      {pacotes.length > 0 ?
        <div className="container mb-6 mt-3 appears-animation">
          <div className="row h-100">
            <div className="col-lg-7 mx-auto text-center mb-4">
              <h1>O Melhor do Mundo Agaxtur</h1>
            </div>
            <div className="col-12">
              <Slider
                {...settings}
                className=""
              >
                {pacotes.map((pacote) => {

                  const slug = slugify(pacote.title, { lower: true });

                  return (
                    <div
                      key={pacote.title}
                      className="card card-span h-100 text-white img-fluid-container"
                      id="ofertas-pacote-card-container"
                      onClick={() => navigate(pacote.destino ? `/${pacote.destino.slug}/${slug}/${pacote._id}` : null)}
                      style={{ cursor: 'pointer' }}
                    >
                      <div className="pacote-box h-100 mx-2">
                        <div className="pacote-img" id={show.toString() && "skeleton-image"}>
                          <LazyLoadImage
                            className="img-fluid"
                            alt={pacote.title}
                            onLoadCapture={() => setShow(true)}
                            style={{ objectFit: 'cover' }}
                            src={`https://agaxtur-uploads.s3.amazonaws.com/${pacote.thumbnail ? pacote.thumbnail : pacote.imageUrl[0]}`}
                          />
                        </div>
                        <div className="pacote-info p-2">
                          {pacote.noites ?
                            <span className="badge bg-primary fs--1 me-2" style={{ display: 'flex', gap: 5, whiteSpace: 'nowrap' }}>
                              <i className="fas fa-calendar"></i> {pacote.noites} noites
                            </span> : null
                          }

                        </div>
                        <div className="pacote-detalhes p-3">
                          <h2 className="fs-1 fs-md-2">{pacote.title}</h2>
                          <div className="pacote-descricao" dangerouslySetInnerHTML={{ __html: `${pacote.description}` }}></div>
                          {pacote.price ?
                            <div>
                              <small className="pacote-texto-valor">{pacote.textoValor}</small> <br />
                              <h3>{pacote.price}</h3>
                            </div>
                            : null
                          }
                          {pacote.valorTotal ?
                            <div className="pactote-texto-total">
                              {pacote.valorTotal}
                            </div>
                            : null
                          }
                          {pacote.departure !== "" ?
                            <p style={{ margin: '2px 0px 0px 0px', color: '#222', padding: 0, fontSize: 11 }}>
                              {pacote.departure}
                            </p>
                            : null
                          }
                          {buttonVisible ?
                            <span className="btn-large btn btn-secondary mt-3" >
                              {pacote.textoBotao}
                            </span>
                            : null
                          }
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
        :
        <div className="container mb-6 mt-3">
          <div className="row h-100">
            <div className="col-lg-7 mx-auto text-center mb-4">
              <h1>O Melhor do Mundo Agaxtur</h1>
            </div>
            <div className="col-12">
              <Slider
              {...settings}
              >
                <OfertasCard />
                <OfertasCard />
                <OfertasCard />
                <OfertasCard />
                <OfertasCard />
                <OfertasCard />
                <OfertasCard />
                <OfertasCard />
              </Slider>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Ofertas;

const OfertasCard = () => {
  return (
    <div
      className="mx-2 card card-span h-100 text-white img-fluid-container"
      id="ofertas-pacote-card-container"
    >
      <div className="pacote-box h-100">
        <div className="pacote-img" id="skeleton-image">
        </div>
        <div className="pacote-info p-2">
        </div>
        <div className="pacote-detalhes p-3">
          <h2 className="fs-1 fs-md-2" id="skeleton-title">Titulo do card</h2>
          <div className="pacote-descricao" >
            <span id="skeleton-description">
              Descrição do Card
              Descrição do Card
              Descrição do Card
              Descrição do Card
              Descrição do Card
            </span>
          </div>
          <div>
            <small className="pacote-texto-valor"></small> <br />
            <h3> </h3>
          </div>
          <div className="pactote-texto-total">
            <span id="skeleton-price" >
              R$11455
            </span>
          </div>
          <span className="btn-large btn mt-3" id="skeleton-price">
          </span>
        </div>
      </div>
    </div>
  )
}