import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import useDimensions from '../../hooks/useDimensions';
import icnBrasil from "../../images/icons/brasil.svg";
import Logo from '../../images/agaxtur-gold.png';
import useReducerActions from '../../reducer';
import { NavigationMobile } from './NavigationMobile';

export function Navigation() {
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { pages_t } = state.extras;
    const { weddings_c, weddings_s } = state.weddings;
    const { travel_c, travel_s } = state.travel;
    const { cruises_c } = state.cruises;
    const { SET_WEDDINGS_C, SET_WEDDINGS_S, SET_TRAVEL_C, SET_TRAVEL_S } = useReducerActions();
    const navigate = useNavigate();
    const [navigationPosition, setNavigationPosition] = useState("");
    const [navController, setNavController] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });
    const { dimensions } = useDimensions();

    async function fetchAllResources() {
        try {
            const [weddings_sData, weddings_cData, travel_cData, travel_sData,] = await Promise.all([
                api.get('/api/casamento').catch(error => { throw new Error('Erro ao buscar pacotes de casamentos') }),
                api.get('/api/casamento/categorias').catch(error => { throw new Error('Erro ao buscar categorias de casamentos') }),
                api.get('/api/destinos').catch(error => { throw new Error('Erro ao buscar pacotes de destinos') }),
                api.get('/api/destinos/subcategorias').catch(error => { throw new Error('Erro ao buscar subcategorias de destinos') }),
            ]);
            dispatch(SET_WEDDINGS_S(weddings_sData.data && weddings_sData.data));
            dispatch(SET_WEDDINGS_C(weddings_cData.data && weddings_cData.data));
            dispatch(SET_TRAVEL_C(travel_cData.data && travel_cData.data));
            dispatch(SET_TRAVEL_S(travel_sData.data && travel_sData.data));
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchAllResources();
        // eslint-disable-next-line
    }, [])

    const Experiencias = pages_t
        .filter(item => item.parentChild === "Experiência" && item.isVisible === "Exibir no menu")

    useEffect(() => {
        const deviceChecker = () => {
            let breakPoint = 992;
            let check = dimensions.width;
            check < breakPoint ? setIsMobile(true) : setIsMobile(false)
        }

        deviceChecker();
        // eslint-disable-next-line
    }, [dimensions.width])

    useEffect(() => {
        if (dimensions.scroll > 200 && navigationPosition === "scroll-active") {
            return;
        } else {
            if (dimensions.scroll > 100) {
                setNavigationPosition("scroll-active");
            } else {
                setNavigationPosition("");
            }
        }
        // eslint-disable-next-line
    }, [dimensions.scroll]);

    const handleRedirect = (slug) => {
        navigate('/blank');
        setTimeout(() => {
            navigate(slug);
        }, [0.1])
    }

    return (
        <>
            <nav className={`navbar navbar-gradient navbar-expand-lg navbar-light fixed-top pb-3 d-block ${navigationPosition}`} data-navbar-on-scroll="data-navbar-on-scroll">
                <div className="container mt-2" id='navbar-container' style={{ margin: '0 auto', padding: 0 }}>
                    <NavLink className="navbar-brand align-self-start" to="/"><img className="d-inline-block" src={Logo} width="140" alt="logo" /></NavLink>
                    <button onClick={() => setNavController(!navController)} className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                    <div style={{ display: navController ? 'block' : 'none' }} className="collapse navbar-collapse border-top d-lg-flex justify-content-lg-end border-lg-0 mt-4 mt-lg-0" id="navbarSupportedContent">
                        <ul style={{ whiteSpace: 'nowrap' }} className="navbar-nav">
                            {
                                !isMobile ? <li className="nav-item dropdown" ><a className="nav-link fw-medium dropdown-toggle active nav-item-mobile-text" aria-current="page" href="#!" id="navbarDropdown destinos-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><FontAwesomeIcon className='nav-item-icon' icon={solid('earth-americas')} /> <span className="nav-item-text" style={{ color: 'white' }}>Destinos Mundo</span></a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        {[...travel_s]
                                            .sort((a, b) => a.title.localeCompare(b.title))
                                            .filter(subcategoria => subcategoria.categoria === '635ac088cfec79385a6cd30b' && subcategoria.isVisible === 'Exibir no menu')
                                            .map(subcategoria => (
                                                <li key={subcategoria._id} >
                                                    <a className="dropdown-item" aria-current="page" href="#!" id="navbarDropdown subcat-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{subcategoria.title}</a>
                                                    <ul className="dropdown-menu dropdown-submenu" aria-labelledby="navbarDropdownMenuLink">
                                                        {[...travel_c]
                                                            .sort((a, b) => a.title.localeCompare(b.title))
                                                            .filter(destino => destino.submenu === subcategoria._id)
                                                            .map(destino => {
                                                                return (
                                                                    <li key={destino._id} className="nav-item dropdown">
                                                                        <Link
                                                                            className="dropdown-item"
                                                                            to={destino.selectDirection === 'SLUG' ?
                                                                                `/destinos/${destino.slug}` : destino.pageUrl
                                                                            }
                                                                            target={destino.selectDirection !== 'SLUG' ? '_blank' : '_self'}
                                                                        >
                                                                            {destino.title}
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            })}
                                                    </ul>
                                                </li>
                                            ))}
                                        {[...travel_c]
                                            .sort((a, b) => a.title.localeCompare(b.title))
                                            .filter(destino => destino.menu === '635ac088cfec79385a6cd30b' && destino.submenu == null)
                                            .map(destino => {
                                                return (
                                                    <li key={destino._id} className="nav-item dropdown">
                                                        <NavLink className="dropdown-item" to={`/destinos/${destino.slug}`}>{destino.title}</NavLink>
                                                    </li>
                                                )
                                            })}
                                    </ul>
                                </li> : <NavigationMobile onLinkPress={() => setNavController(false)} variant={'mundo'} icon={<FontAwesomeIcon icon={solid('earth-americas')} />} destinos={[...travel_c]} subCategorias={[...travel_s]} />
                            }
                            {
                                !isMobile ? <li className="nav-item dropdown"><a className="nav-link fw-medium dropdown-toggle active nav-item-mobile-text" aria-current="page" href="#!" id="navbarDropdown categoria-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src={icnBrasil} className='nav-item-icon' alt="" /> <span className="nav-item-text">Destinos Brasil</span></a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        {[...travel_s]
                                            .sort((a, b) => a.title.localeCompare(b.title))
                                            .filter(subcategoria => subcategoria.categoria === '635ac080cfec79385a6cd307' && subcategoria.isVisible === "Exibir no menu")
                                            .map(subcategoria => {
                                                return (
                                                    <li key={subcategoria._id}>
                                                        <a className="dropdown-item" aria-current="page" href="#!" id="navbarDropdown subcat-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{subcategoria.title}</a>
                                                        <ul className="dropdown-menu dropdown-submenu" aria-labelledby="navbarDropdownMenuLink">
                                                            {[...travel_c]
                                                                .sort((a, b) => a.title.localeCompare(b.title))
                                                                .filter(destino => destino.submenu === subcategoria._id)
                                                                .map(destino => {
                                                                    return (
                                                                        <li key={destino._id} className="nav-item dropdown">
                                                                            <Link className="dropdown-item" to={`/destinos/${destino.slug}`}>{destino.title}</Link>
                                                                        </li>
                                                                    )
                                                                })}
                                                        </ul>
                                                    </li>
                                                )
                                            }
                                            )
                                        }
                                        {[...travel_c]
                                            .filter(destino => destino.menu === '635ac080cfec79385a6cd307' && !destino.submenu)
                                            .map(destino => {
                                                return (
                                                    <li key={destino._id} className="nav-item dropdown">
                                                        <Link className="dropdown-item" to={`/destinos/${destino.slug}`}>{destino.title}</Link>
                                                    </li>
                                                )
                                            })}
                                    </ul>
                                </li> : <NavigationMobile onLinkPress={() => setNavController(false)} variant={'brasil'} icon={icnBrasil} destinos={[...travel_c]} subCategorias={[...travel_s]} />
                            }
                            <li className="nav-item dropdown"><a className="nav-link fw-medium dropdown-toggle active nav-item-mobile-text" aria-current="page" href="#!" id="navbarDropdown cruzeiros-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><FontAwesomeIcon className='nav-item-icon' icon={solid('ship')} /> <span className="nav-item-text">Cruzeiros</span></a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                    {[...cruises_c]
                                        .sort((a, b) => a.title.localeCompare(b.title))
                                        .map(categoria => {
                                            return (
                                                <li className="nav-item dropdown" key={categoria.title}>
                                                    <Link className="dropdown-item" to={`/cruzeiros/${categoria.slug}`} onClick={() => setNavController(false)}>{categoria.title}</Link>
                                                </li>)
                                        })}
                                </ul>
                            </li>
                            {
                                !isMobile ? <li className="nav-item dropdown"><a className="nav-link fw-medium dropdown-toggle active nav-item-mobile-text" aria-current="page" href="#!" id="navbarDropdown categoria-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><FontAwesomeIcon className='nav-item-icon' icon={solid('suitcase-rolling')} />  <span className="nav-item-text">Casamentos</span></a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        {weddings_c
                                            .map(subcategoria => {
                                                return (
                                                    <li key={subcategoria._id}>
                                                        <a className="dropdown-item" aria-current="page" href="#!" id="navbarDropdown subcat-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{subcategoria.title}</a>
                                                        <ul className="dropdown-menu dropdown-submenu" aria-labelledby="navbarDropdownMenuLink">
                                                            {[...weddings_s]
                                                                .sort((a, b) => a.title.localeCompare(b.title))
                                                                .filter(destino => destino.categoria === subcategoria._id)
                                                                .map(destino =>
                                                                (
                                                                    <li key={destino._id} className="nav-item dropdown">
                                                                        <Link className="dropdown-item" to={`/casamento/${destino.slug}`}>{destino.title}</Link>
                                                                    </li>
                                                                )
                                                                )}
                                                        </ul>
                                                    </li>
                                                )
                                            }
                                            )
                                        }
                                        {weddings_s
                                            .filter(destino => destino.slug && destino.slug && !destino.categoria)
                                            .map(destino => {
                                                return (
                                                    <li key={destino._id} className="nav-item dropdown" onClick={() => setNavController(false)}>
                                                        <Link className="dropdown-item" to={`/casamento/${destino.slug}`}>{destino.title}</Link>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </li> : <NavigationMobile variant={'casamento'} onLinkPress={() => setNavController(false)} casamento={weddings_c} subCasamento={weddings_s} icon={<FontAwesomeIcon icon={solid('suitcase-rolling')} />} />
                            }
                            {
                                Experiencias.length > 0 && (
                                    <li className="nav-item dropdown">
                                        <a className="nav-link fw-medium dropdown-toggle active" aria-current="page" href="#!" id="navbarDropdown experiencias-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <FontAwesomeIcon className='nav-item-icon' icon={solid('parachute-box')} />
                                            <span className="nav-item-text"> Experiências</span>
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                            {
                                                Experiencias.map(item =>
                                                    <li className="nav-item dropdown" key={item.title} onClick={() => handleRedirect(`/${item.slug}`)}>
                                                        {/* eslint-disable-next-line */}
                                                        <Link className="dropdown-item" to={`/${item.slug}`} style={{ cursor: 'pointer' }} onClick={() => setNavController(false)}>{item.title}</Link>
                                                    </li>)
                                            }
                                        </ul>
                                    </li>
                                )
                            }
                            <li className="nav-item" onClick={() => setNavController(false)}><Link to="/circuitos" className="nav-link"><FontAwesomeIcon className='nav-item-icon' icon={solid('bus')} /> <span className="nav-item-text">Circuitos</span></Link></li>
                            {
                                pages_t.filter(item => item.isVisible === "Exibir no menu" && item.parentChild === "Sem categoria" && item.plataforma !== "B2B")
                                    .map(pagina => (
                                        <li className="nav-item" key={pagina._id} onClick={() => handleRedirect(`/${pagina.slug}`)}>
                                            <Link className="nav-link fw-medium active" aria-current="page" onClick={() => setNavController(false)}>
                                                {
                                                    pagina.fa_icon === "" ? <FontAwesomeIcon className='nav-item-icon' icon={solid('file-pen')} /> :
                                                        <FontAwesomeIcon className='nav-item-icon' icon={`${pagina.fa_icon}`} />
                                                }
                                                <span className="nav-item-text"> {pagina.title}</span>
                                            </Link>
                                        </li>
                                    ))}
                            <li className="nav-item"><Link to="/lojas-agaxtur" className="nav-link" onClick={() => setNavController(false)}><FontAwesomeIcon className='nav-item-icon' icon={solid('house')} /> <span className="nav-item-text">Lojas</span></Link></li>
                            <li className="nav-item"><Link to="/folheteria" className="nav-link" onClick={() => setNavController(false)}><FontAwesomeIcon className='nav-item-icon' icon={solid('file')} /> <span className="nav-item-text">Folheteria</span></Link></li>
                            <AreaDoAgenteDropDownMenu navController={() => setNavController(false)} />
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

const AreaDoAgenteDropDownMenu = ({ navController }) => {

    const AREA_AGENTE_LINKS = [
        {
            title: "Portal do agente",
            link: "https://agenteagaxtur.com.br/"
        },
        {
            title: "Portal terrestre",
            link: "https://agaxtur.infotravel.com.br/infotravel/admin/main.xhtml"
        },
        {
            title: "Portal marítimo",
            link: "https://agaxtur.travellink.com.br/agencias30/guiAutenticador.aspx?erro=!*!%3fwo0b4r5a%3f!*!__zvidlu2abj%2frzjskzqgtzuhlzas0ayko7mno%2fzy8qx4npprxannqgcoug4vt84eiii+pilyqv9y7qzkujci6t3iecvvdninyljrl0%2f0oj8j148zi95jgydnm27a8nkz+0yptj1h%2f9uieko0rkxwmlnribrdpyleiebcjhndxiag%3d"
        },
    ]

    return (
        <li className="nav-item dropdown">
            <a className="nav-link fw-medium dropdown-toggle active nav-item-mobile-text" aria-current="page" href="#!" id="navbarDropdown categoria-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <FontAwesomeIcon className='nav-item-icon' icon={solid('address-card')} />
                <span className="nav-item-text" style={{ marginLeft: '4px' }}>Área do agente</span>
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                {AREA_AGENTE_LINKS.map((area, key) => (
                    <li key={key} className="nav-item dropdown" onClick={() => {
                        navController();
                        window.open(area.link, "_blank");
                    }}>
                        <Link className="dropdown-item">{area.title}</Link>
                    </li>
                ))}
            </ul>
        </li>
    )
}
