import React, { useEffect, useState } from "react";
import ImageGallery from 'react-image-gallery';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingSpinner from '../components/LoadingSpinner/index';

const Pacotes = () => {
    const state = useSelector((state) => state);
    const { travel_p } = state.travel;
    const { id } = useParams();
    const [pacote, setPacote] = useState({});
    const [images, setImages] = useState([]);

    useEffect(() => {
        travel_p.filter((pac) => pac._id === id).map((pac) => {
            setPacote(pac);
            setImages(pac.imageUrl);
            return null;
        })

        if (pacote.title) {
            document.title = `${pacote.title} - Agaxtur Viagens`;
        }
        if (pacote.description) {
            document.querySelector('meta[name="description"]').setAttribute('content', `${pacote.title} por apenas ${pacote.price}. ${pacote.description}`);
        }
    }, [id, travel_p, pacote]);

    const galleryItems = images ?
        images.map((image) => (
            {
                original: `https://agaxtur-uploads.s3.amazonaws.com/${image}`,
                thumbnail: `https://agaxtur-uploads.s3.amazonaws.com/${image}`,
            }
        )) : [];

    return (
        <>
            {
                travel_p.length > 0 ?
                    pacote.title && pacote.imageUrl &&
                    <main style={{ minHeight: '95vh' }} className="mb-6">
                        <div className="container">
                            <div className="row">
                                <h2 className="mb-3">{pacote.title}</h2>

                                <div className="col-12 col-md-6">
                                    <ImageGallery items={galleryItems} />
                                </div>
                                <div className="col-12 col-md-6 sm-mt-3">
                                    <h4>{pacote.destino ?
                                        (
                                            <div>
                                                <a href={"/destinos/" + pacote.destino.slug}>{pacote.destino.title}</a>
                                            </div>
                                        )
                                        : null}</h4>
                                    <p><b>{pacote.noites} noites</b> {pacote.partida ? (<span>-  {new Date(pacote.partida).setHours(0, 0, 0, 0) && new Date(pacote.partida).toLocaleDateString('pt-BR')} a  {new Date(pacote.partida).setHours(0, 0, 0, 0) && new Date(pacote.retorno).toLocaleDateString('pt-BR')}</span>) : null}
                                    </p>
                                    <p>{pacote.description}</p>

                                    <div dangerouslySetInnerHTML={{ __html: pacote.conteudo }} />
                                    <p>{pacote.textoValor} </p>
                                    <h3>{pacote.price}</h3>
                                    <p className="text-mute">{pacote.valorTotal ? pacote.valorTotal : pacote.total}</p>
                                    {/* <a href={pacote.url} className="btn btn-primary">{pacote.textoBotao}</a> */}
                                    <a
                                        href="https://lp.agaxtur.com.br/atendimento"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="btn btn-primary">{pacote.textoBotao}</a>
                                </div>
                            </div>
                        </div>
                    </main>
                    :
                    <main className="blank-default-container">
                        <LoadingSpinner />
                    </main>
            }
        </>
    );
};

export default Pacotes;