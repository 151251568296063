import axios from "axios";
import React, { useEffect, useState } from "react";
import ImageGallery from 'react-image-gallery';
import { Link, useParams } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";
import { useFetch } from "../hooks/useFetch";

const CasamentoPacotes = () => {
    const { id } = useParams();
    const [pacote, setPacote] = useState({});
    const [images, setImages] = useState([]);
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(true);

    const { data: Destinos } = useFetch(
        `${process.env.REACT_APP_API_URL}/api/destinos`
    );

    useEffect(() => {
        const url = `${process.env.REACT_APP_API_URL}/api/casamento/pacotes/${id}`;
        const fetchData = async () => {
            try {
                const { data } = await axios.get(url);
                setPacote(data);
                setImages(data.imageUrl);
                setIsLoading(false);
            } catch (err) {
                console.error(err);
            }
        };
        fetchData();
    }, [id]);

    useEffect(() => {
        if (pacote.title) {
            document.title = `${pacote.title} - Agaxtur Viagens`;
        }
        if (pacote.description) {
            document.querySelector('meta[name="description"]').setAttribute('content', `${pacote.title} por apenas ${pacote.price}. ${pacote.description}`);
        }
    }, [pacote]);

    const galleryItems = images ?
        images.map((image) => (
            {
                original: `https://agaxtur-uploads.s3.amazonaws.com/${image}`,
                thumbnail: `https://agaxtur-uploads.s3.amazonaws.com/${image}`,
            }
        )
        )
        : [];

    return (
        <>
            {
                !isLoading ?
                    <main>
                        {pacote.title && pacote.imageUrl &&
                            <div className="container">
                                <div className="row">
                                    <h2 className="mb-3">{pacote.title}</h2>

                                    <div className="col-12 col-md-6">
                                        <ImageGallery items={galleryItems} />
                                    </div>
                                    <div className="col-12 col-md-6 sm-mt-3">
                                        <h4>
                                            {pacote.destino &&
                                                <div>
                                                    <Link
                                                        to={`/destinos/${Destinos.length > 0 && Destinos.filter((item) => item._id === pacote.destino)[0].slug}`}
                                                    >
                                                        {Destinos.length > 0 && Destinos.filter((item) => item._id === pacote.destino)[0].title}
                                                    </Link>
                                                </div>

                                            }
                                        </h4>
                                        <p>{pacote.description}</p>

                                        <div dangerouslySetInnerHTML={{ __html: pacote.conteudo }} />
                                        <p>{pacote.textoValor} </p>
                                        <h3>{pacote.price}</h3>
                                        <p className="text-mute">{pacote.total}</p>
                                        <a
                                            href="https://lp.agaxtur.com.br/atendimento"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="btn btn-primary">{pacote.textoBotao}</a>
                                    </div>
                                </div>
                            </div>
                        }
                    </main>
                    :
                    <main className="blank-default-container">
                        <LoadingSpinner />
                    </main>
            }
        </>

    );
};

export default CasamentoPacotes;