import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import slugify from 'slugify';

import './styles.css';

export const PacoteCard = ({ pacote, showButton, cruzeiro, uniqueLink, circuito }) => {
  // const buttonVisible = showButton !== undefined ? showButton : false;
  const navigateToLink = (link) => {
    window.location.assign(link);
  };

  const slug = slugify(pacote.title, { lower: true });

  const packageLink = circuito ? `/circuitos/${slug}/${pacote._id}` :
                      cruzeiro ? `/cruzeiros/${pacote.slug}` :
                      pacote.destino ? `/${pacote.destino.slug}/${slug}/${pacote._id}` :
                      null

  return (
    <div onClick={() => !packageLink && navigateToLink(uniqueLink)} key={pacote.title} className="col-6 col-md-3 col-lg-3 mb-2"
      id="geral-pacote-card-container" style={{ cursor: 'pointer' }}>
      <a href={packageLink}>
        <div className="pacote-box h-100">
          <div className="pacote-img" id="skeleton-image">
            <LazyLoadImage
              className="img-fluid"
              alt={""}
              id="skeleton-image"
              src={`https://agaxtur-uploads.s3.amazonaws.com/${pacote.thumbnail ? pacote.thumbnail : pacote.imageUrl[0]}`}
            />
          </div>
          <div className="pacote-info p-2">
            {pacote.noites ?
              <span className="badge bg-primary fs--1 me-2" style={{ display: 'flex', gap: 5, whiteSpace: 'nowrap' }}>
                <i className="fas fa-calendar" ></i>
                {pacote.noites} noites
              </span> : null
            }

          </div>
          <div className="pacote-detalhes p-3" >
            <h2 className="fs-1 fs-md-2">{pacote.title}</h2>
            {!cruzeiro ?
              <div
                className="pacote-descricao"
                dangerouslySetInnerHTML={{ __html: `${pacote.description}` }}>
              </div>
              : null}
            <div>
              {pacote.price ?
                <div>
                  <small className="pacote-texto-valor">{pacote.textoValor}</small> <br />
                  <h3>{pacote.price}</h3>
                </div>
                : null
              }
              {pacote.valorTotal ?
                <div className="pactote-texto-total">
                  {pacote.valorTotal}
                </div>
                : null
              }
              {pacote.departure !== "" ?
                <p style={{ margin: '2px 0px 0px 0px', color: '#222', padding: 0, fontSize: 11 }}>
                  {pacote.departure}
                </p>
                : null
              }
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export const SkeletonCard = () => {
  // const buttonVisible = showButton !== undefined ? showButton : false;

  return (
      <div className="col-6 col-md-3 col-lg-3 mb-2 skeleton-container"
          id="geral-pacote-card-container"
          style={{ cursor: 'pointer' }}
      >
          <div className="pacote-box h-100">
              <div className="pacote-img" id="skeleton-image">
                  {/* imagem do card  */}
              </div>
              <div className="pacote-detalhes p-3" >
                  <h2 className="fs-1 fs-md-2" id="skeleton-title">Titulo do card</h2>
                  <div className="pacote-descricao">
                      <span id="skeleton-description">
                          Descrição do Card
                          Descrição do Card
                          Descrição do Card
                          Descrição do Card
                          Descrição do Card
                      </span>
                  </div>
                  <div>
                      <div>
                          <small id="skeleton-price-title" className="pacote-texto-valor" style={{ color: "transparent" }}>a partir de:</small> <br />
                          <h3 id="skeleton-small-price">10 x R$265</h3>
                      </div>
                      <div className="pactote-texto-total">
                          <span id="skeleton-price" >
                              R$11455
                          </span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
};
