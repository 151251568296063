import axios from "axios";
import React, { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import facebook from "../../images/icons/facebook.svg";
import instagram from "../../images/icons/instagram.svg";
import twitter from "../../images/icons/youtube.png";
import logoAguiaBranca from "../../images/logo-aguia-white.png";
import logo from "../../images/agaxtur.png";
import useReducerActions from '../../reducer/index';

export function Footer() {
  const { SET_PAGES_B, SET_RECLAME_AQUI } = useReducerActions();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { pages_b, pages_t, reclame_aqui } = state.extras;

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  async function fetchAllResources() {
    try {
      const paginas_infData = await api.get('/api/paginas');
      dispatch(SET_PAGES_B(paginas_infData.data));
      const reclameAquiLogo = await api.get('/api/reclame-aqui');
      dispatch(SET_RECLAME_AQUI(
        reclameAquiLogo.data.filter((item) => item.isVisible === "Exibir")[0].imageUrl));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!pages_b.length > 0) {
      fetchAllResources();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <section className="py-0 overflow-hidden">
      {pages_t.length > 0 &&
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <div className="py-7">
                <LazyLoadImage
                  className="d-inline-block mb-2"
                  src={logo}
                  width="140"
                  alt="logo"
                />
                <ul className="list-unstyled mt-2">

                  {pages_b.filter((item) => item.plataforma !== "B2B").map(page => (
                    <li key={page._id} className="mb-2">
                      <Link className="text-800 fw-bold text-decoration-none" to={page.selectDirection === 'URL' ? page.pageUrl : `/${page.slug}`}>{page.title}</Link>
                    </li>))}
                  <li className="mb-2">
                    <a
                      className="text-800 fw-bold text-decoration-none"
                      href="mailto:ouvidoria@agaxturviagens.com.br"
                    >
                      Ouvidoria
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-lg-6 gradient-row-footer gradient-base-dark-blue-right">
              <div className="p-2 py-6 p-md-6">
                <p className="text-light">
                  <i className="fas fa-map-marker-alt me-3"></i>
                  <span className="text-light lh-lg">
                    Rua Frei Caneca, 1.246 – 10° andar – Consolação – CEP
                    01307-002 - São Paulo (SP)
                  </span>
                </p>
                <div className="mt-2 mb-3">
                  <a href="https://www.facebook.com/agaxtur/"
                    aria-label="Ir para Facebook Agaxtur">
                    {" "}
                    <LazyLoadImage className="me-3" src={facebook} alt="" />
                  </a>
                  <a href="https://www.youtube.com/user/agaxturturismo"
                    aria-label="Ir para YouTube Agaxtur">
                    {" "}
                    <LazyLoadImage className="me-3" src={twitter} alt="" />
                  </a>
                  <a href="https://www.instagram.com/agaxtur/"
                    aria-label="Ir para Instagram Agaxtur">
                    {" "}
                    <LazyLoadImage className="me-3" src={instagram} alt="" />
                  </a>
                </div>
                <p className="text-light">
                  <small>
                    Os valores apresentados podem sofrer alterações de acordo
                    com o câmbio do dia. Preços por pessoa em apto / cabine dupla, 
                    passagem aérea com saída de São Paulo sujeito a alteração e 
                    disponibilidade sem aviso prévio.
                  </small>
                </p>
                <small className="text-light">
                  AGAXTUR AGÊNCIA DE VIAGENS E TURISMO S.A. – Ministério do
                  Turismo. Cadastur 26.001847.10.0001-3.
                  CNPJ:60.621.596/0001-79.
                </small>
                <div className="mt-4 d-flex align-items-center">
                  <div className="d-flex flex-column">
                    <p className="text-800 text-white">Uma empresa do</p>
                    <LazyLoadImage
                      className="d-inline-block mb-4 aguia-branca-image"
                      src={logoAguiaBranca}
                      alt="logo"
                    />
                  </div>
                  {
                    reclame_aqui &&
                    <LazyLoadImage
                      className="d-inline-block reclame-aqui-image"
                      src={`https://agaxtur-uploads.s3.amazonaws.com/${reclame_aqui}`}
                      height="120"
                      alt="logo"
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </section>
  );
}
