import React, { useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import Slider from "react-slick/lib/slider";
import './styles.css';

const Header = () => {
  const state = useSelector((state) => state);
  const { banner } = state.extras;
  const [show, setShow] = useState(false);
  const carouselRef = useRef(null);

  const breakPoints = [
    {
      breakpoint: 380,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    waitForAnimate: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: false,
    prevArrow: false,
    responsive: breakPoints,
    appendDots: dots => (
      <div>
        <ul className="carousel-dot" style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
  };

  return (
    <header className="header-container mb-5">
      {
        banner &&
        <Slider
          {...settings}
          className={`pt-7 ${show && "appears-animation"}`}  
          ref={carouselRef}
        >
          {
            banner.filter(item => (item.platform === "GERAL" || item.platform === "B2C") && item.show).map((banner) => {
              return (
                <div
                  key={banner.title}
                >
                  <a href={banner.buttonurl}
                    aria-label={`Clique para acessar informações sobre ${banner.title}`}>
                    <LazyLoadImage
                      alt={banner.title}
                      key={banner.title}
                      src={`https://agaxtur-uploads.s3.amazonaws.com/${banner.imageUrl}`}
                      className="w-100 banner-image"
                      onLoadCapture={() => setShow(true)}
                      aria-label={`Banner agaxtur sobre ${banner.title}`}
                      style={{ opacity: show ? 1 : 0 }}
                      id="skeleton-image"
                    />
                  </a>
                </div>
              );
            })
          }
        </Slider>
      }
    </header>
  );
};

export default Header;
