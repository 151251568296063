import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CasamentoCard } from "../components/CasamentoCard";
import FormularioSharp from "../components/FormularioSharp";
import { SkeletonCard } from "../components/PacoteCard";
import useReducerActions from "../reducer";

const Casamento = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { weddings_p } = state.weddings;
  const { SET_WEDDINGS_P } = useReducerActions();
  const { slug } = useParams();
  const [casamento, setCasamento] = useState({});
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  async function fetchAllResources() {
    try {
      const weddings_pData = await api.get('/api/casamento/pacotes');
      dispatch(SET_WEDDINGS_P(weddings_pData.data));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!weddings_p.length > 0) {
      fetchAllResources();
    }
    // eslint-disable-next-line
  }, []);

  document.title = `Casamentos - Agaxtur Viagens`;

  const fetch = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/casamento/${slug}`
      );
      setCasamento(data[0]);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, [slug]);

  return (
    <>
      <div className="destinos">
        <header className="text-center" style={{backgroundColor: '#0d4990'}}>
          <h1>{casamento.title}</h1>
          <h3>
            <span className="badge bg-secondary">
              {casamento.headline}
            </span>
          </h3>
        </header>
        <div className="container">
          <div id="pacotes" className="py-3 mt-4">
            {casamento.description ? (
              <div className="row pb-4">
                <div className="col-12">
                  <h2 className="mb-3">
                    Sobre <span className="azul-claro">{casamento.title}</span>
                  </h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: `${casamento.description}` }}
                  ></div>
                </div>
              </div>
            ) : null}
            <h2 className="mb-4 text-center">
              {casamento.textoInicial ? casamento.textoInicial : 'Encontre o pacote ideal para o seu casamento'}
            </h2>
            {weddings_p.length > 0 ?
              <div className="row mb-4">
                {[...weddings_p].sort((a, b) => a.ordem - b.ordem).map((pacote) => pacote.subcategoria === casamento._id &&
                  <CasamentoCard pacote={pacote} slugDestino={slug} showButton={true} key={pacote._id} />
                )}
              </div>
              :
              <div className="row mb-4">
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
              </div>
            }
          </div>
        </div>
      </div>
      <FormularioSharp />
    </>
  );
};

export default Casamento;