import React, { useEffect } from "react";
import FormularioSharp from "../components/FormularioSharp";
import Stores from "../components/Stores";

const Destino = "Lojas";

export default function Lojas() {
  useEffect(() => {
    document.title = `${Destino} - Agaxtur Viagens`;
  }, []);

  return (
    <>
      <Stores />
      <FormularioSharp />
    </>
  );
};
