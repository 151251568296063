import React from 'react';
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Blank from "./routes/Blank";
import Brasil from "./routes/Brasil";
import Casamento from "./routes/Casamento";
import CasamentoPacotes from "./routes/CasamentoPacotes";
import Circuitos from "./routes/Circuitos";
import Cruzeiros from "./routes/Cruzeiros";
import Destinos from "./routes/Destinos";
import Home from "./routes/Home";
import Lojas from "./routes/Lojas";
import Pacotes from "./routes/Pacotes";
import Paginas from "./routes/Paginas";
import Passagens from "./routes/Passagens";
import Folheteria from './routes/Folheteria';

export default function Router() {
  const state = useSelector((state) => state);
  const { pages_t } = state.extras;
  
  return (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="destinos/:slug" element={<Destinos />} />
        <Route path="casamento/:slug" element={<Casamento />} />
        <Route path="casamento/:destino/:title/:id" element={<CasamentoPacotes />} />
        {pages_t.filter((pagina) => pagina.isVisible === "Exibir no menu").map((pagina) => (
          <Route key={pagina._id} path={`/${pagina.slug}`} element={
            <Passagens paginaId={pagina._id} />} />))};
        <Route path="circuitos" element={<Circuitos />} />
        <Route path=":slug" element={<Paginas />} />
        <Route path="/:destino/:title/:id" element={<Pacotes />} />
        <Route path="cruzeiros/:slug" element={<Cruzeiros />} />
        <Route path="lojas-agaxtur" element={<Lojas />} />
        <Route path="brasil" element={<Brasil />} />
        <Route path="folheteria" element={<Folheteria />} />
        <Route path="blank" element={<Blank />} />
      </Routes>
  )
}
