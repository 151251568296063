import { configureStore } from "@reduxjs/toolkit";
import travelReducer from "../reducer/travel/travelReducer";
import cruisesReducer from "../reducer/cruises/cruisesReducer";
import extrasReducer from "../reducer/extras/extrasReducer";
import weddingsReducer from "../reducer/weddings/weddingsReducer";

export const store = configureStore({
    reducer: {
        travel: travelReducer,
        cruises: cruisesReducer,
        extras: extrasReducer,
        weddings: weddingsReducer,
    },
});
