import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { fetchAllResources } from "./api/api";
import { Footer } from "./components/Footer";
import { Navigation } from "./components/Navigation";
import { WhatsApp } from "./components/WhatsApp";
import ScrollToTop from "./helpers/ScrollToTop";
import useReducerActions from "./reducer";

import Router from "./router";

function App() {
  const dispatch = useDispatch();

  const {
    SET_CRUISES_C,
    SET_CRUISES_P,
    SET_TRAVEL_P,
    SET_PAGES_T,
    SET_TEXTS 
  } = useReducerActions();

  useEffect(() => {
    async function fetchData() {
      try {
        const { paginasSupData, destinosData, cruzeirosData, cruzeirosCatData, textosData } = await fetchAllResources();
        dispatch(SET_PAGES_T(paginasSupData));
        dispatch(SET_TRAVEL_P(destinosData));
        dispatch(SET_CRUISES_P(cruzeirosData));
        dispatch(SET_CRUISES_C(cruzeirosCatData));
        dispatch(SET_TEXTS(textosData));
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [dispatch, SET_CRUISES_C, SET_CRUISES_P, SET_PAGES_T, SET_TEXTS, SET_TRAVEL_P]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navigation />
      <Router />
      <WhatsApp />
      <Footer />
    </BrowserRouter>
  );
}
export default App;
