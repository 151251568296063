import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // pacotes, categorias, subcategorias, organização dos menus
  travel_p: [],
  travel_c: [],
  travel_s: [],
  travel_m: [],
};

export const travelSlice = createSlice({
  name: "travel",
  initialState,
  reducers: {
    SET_TRAVEL_P: (state, action) => {
      state.travel_p = action.payload;
    },
    SET_TRAVEL_C: (state, action) => {
      state.travel_c = action.payload;
    },
    SET_TRAVEL_S: (state, action) => {
      state.travel_s = action.payload;
    },
    SET_TRAVEL_M: (state, action) => {
      state.travel_m = action.payload;
    },
  },
});

export const {
  SET_TRAVEL_P,
  travel_p,
  SET_TRAVEL_C,
  travel_c,
  SET_TRAVEL_S,
  travel_s,
  SET_TRAVEL_M,
  travel_m,
} = travelSlice.actions;

export default travelSlice.reducer;
