import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    banner: null,
    flyer: [],
    stores: [],
    texts: [],
    pages_t: [],
    pages_b: [],
    reclame_aqui: null,
};

export const extrasSlice = createSlice({
    name: "extras",
    initialState,
    reducers: {
        SET_FLYER: (state, action) => {
            state.flyer = action.payload;
        },
        SET_STORES: (state, action) => {
            state.stores = action.payload;
        },
        SET_TEXTS: (state, action) => {
            state.texts = action.payload;
        },
        SET_PAGES_T: (state, action) => {
            state.pages_t = action.payload;
        },
        SET_PAGES_B: (state, action) => {
            state.pages_b = action.payload;
        },
        SET_BANNER: (state, action) => {
            state.banner = action.payload;
        },
        SET_RECLAME_AQUI: (state, action) => {
            state.reclame_aqui = action.payload;
        }
    },
});

export const {
    SET_FLYER,
    flyer,
    SET_STORES,
    stores,
    SET_TEXTS,
    texts,
    SET_PAGES_T,
    pages_t,
    SET_PAGES_B,
    pages_b,
    SET_BANNER,
    banner,
    SET_RECLAME_AQUI,
    reclame_aqui,
} = extrasSlice.actions;

export default extrasSlice.reducer;
