import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  weddings_p: [],
  weddings_c: [],
  weddings_s: [],
};

export const weddingsSlice = createSlice({
  name: "weddings",
  initialState,
  reducers: {
    SET_WEDDINGS_P: (state, action) => {
      state.weddings_p = action.payload;
    },
    SET_WEDDINGS_C: (state, action) => {
      state.weddings_c = action.payload;
    },
    SET_WEDDINGS_S: (state, action) => {
      state.weddings_s = action.payload;
    },
  },
});

export const { SET_WEDDINGS_P, weddings_p, SET_WEDDINGS_C, weddings_c, SET_WEDDINGS_S, weddings_s } = weddingsSlice.actions;

export default weddingsSlice.reducer;
